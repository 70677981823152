const CourseTable = (personinfo, t) => {
    return [
        {
            cell: (row) => (
                // Render buttons within a cell for Lecturer
                personinfo?.personType === 'Lecturer' && (
                    <div className="btn-group dropend">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fas fa-ellipsis-h"></i>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href={`/scoresheet/${row.uuid}`}>
                                    <small>{t('course.mark_scoresheet')}</small>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href={`/add-question/${row.uuid}/exam`}>
                                    <small>{t('course.exam_question')}</small>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href={`/add-question/${row.uuid}/test`}>
                                    <small>{t('course.test_question')}</small>
                                </a>
                            </li>
                        </ul>
                    </div>
                )
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: t('course.title'),
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: t('course.course_code'),
            selector: (row) => row.code,
            sortable: true,
        },
        {
            name: t('course.units'),
            selector: (row) => row.units,
            sortable: true,
        },
    ];
};

export default CourseTable;
