import React from "react";
import API from "../../api";

export const LecturerInfo = ({personinfo, headers, t}) => {
    const [personDetail, setPersonDetail] = React.useState([]);

    React.useEffect(() => {
        API.post('lecturer-info', {}, {headers})
            .then(res => setPersonDetail(res.data))
    }, []);

    return (
        <ul className="list-group mainnetinfo">
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.fullname')}</span>
                <b>{personinfo.firstname} {personinfo.lastname}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.lecturer_code')}</span>
                <b>{personDetail.code}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.gender')}</span>
                <b>{personDetail.sex ? t('dashboard.lecturer.male') : t('dashboard.lecturer.female')}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.email')}</span>
                <b>{personDetail.email}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.telephone')}</span>
                <b>{personDetail.telephone}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.whatsapp')}</span>
                <b>{personDetail.whatsapptelephone}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.lecturer.department')}</span>
                <b>{personDetail.departmentTitle} | {personDetail.departmentCode}</b>
            </li>
        </ul>
    );
};

export default LecturerInfo;
