import {initializeApp} from "firebase/app";
import {getPerformance} from "firebase/performance";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_BUCK,
    messagingSenderId: process.env.REACT_APP_MESSAGER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MESUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Performance Monitoring and get a reference to the service
getPerformance(app);
getAnalytics(app);