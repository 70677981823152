import StudentInfo from "../Dashboard/StudentInfo";
import LecturerInfo from "../Dashboard/LecturerInfo";
import React from "react";
import API from "../../api";
import warningAlert from "../../services/AlertS/warning";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";
import AgentInfo from "../Dashboard/AgentInfo";
import { useTranslation } from "react-i18next";

export const UserProfile = ({ headers, personinfo, token }) => {
    const { t,i18n } = useTranslation(); // Translation hook
    const [personfileTypes, setPersonfileTypes] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [updateUploadables, setUpdateUploadables] = React.useState('');
    const currentLanguage = i18n.language;
    React.useEffect(() => {
        const req = async () => {
            try {
                const res = await API.post("get_file_types", {}, { headers });
                setPersonfileTypes(res.data);
            } catch (e) {
                errorAlert(t("userProfile.errorFetch"));
            }
        };
        req();
    }, [updateUploadables,headers,t]);

    const handleFileUpload = (filetype, event) => {
        const file = event.target.files[0];
        const maxSizeFormatted = String(filetype.maxSize).length > 6 ?
            `${String(filetype.maxSize).slice(0, 1)} MB` : `${String(filetype.maxSize).slice(0, 1)} KB`;

        if (file.size > Number(filetype.maxSize)) {
            warningAlert(t("userProfile.fileUploadError.title"), `${t("userProfile.fileUploadError.message")} ${maxSizeFormatted}`);
            event.target.value = "";
            return false;
        }

        const fileData = new FormData();
        fileData.append("file", file);

        setUploadFiles((prevState) => ({
            ...prevState,
            [filetype.uuid]: fileData.get("file"),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const req = async () => {
            try {
                const res = await API.post("upload_file_types", uploadFiles, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (res.data === "saved") {
                    setUpdateUploadables(Math.random());
                    successAlert(t("userProfile.uploadSuccess.title"), t("userProfile.uploadSuccess.message"));
                    setUploadFiles({});
                }
            } catch (e) {
                errorAlert(t("userProfile.uploadError.title"), e.response?.data || t("userProfile.uploadError.message"));
            }
        };
        req();
    };

    const baseURL = API.defaults.baseURL.slice(0, -4);

    const filetypeOutPut = personfileTypes.map((filetype, index) => {
        const fileformat = {
            images_only: ".png,.jpeg,.jpg,.webp",
            docs_only: ".pdf,.pptx,.doc,.docx",
            docs_images_only: ".pdf,.pptx,.doc,.docx,.png,.jpeg,.jpg,.webp"
        }[filetype.fileFormat] || "";

        return (
            <div className="col-sm-12 col-lg-6" key={index}>
                {filetype.uploaded && !filetype.isModifiableByUser && (
                    <div className="mb-3">
                        <p className="text-center">{currentLanguage === "en" ? filetype.libelle : currentLanguage === "fr" ? filetype.libelleFr : filetype.libelle}</p>
                        <h4 className="text-center mt-3">
                            <a
                                className="text-decoration-none dynmatxt"
                                href={`${baseURL}${filetype.location}`}
                                target="_blank" rel="noreferrer"
                                download={true}
                            >
                                {t("userProfile.download")} <i className="fas fa-download"></i>
                            </a>
                        </h4>
                    </div>
                )}
                {filetype.uploaded && filetype.isModifiableByUser && (
                    <div className="mb-3">
                        <h4 className="text-center mt-3">
                            <a
                                className="text-decoration-none dynmatxt"
                                href={`${baseURL}${filetype.location}`}
                                target="_blank" rel="noreferrer"
                                download={true}
                            >
                                {t("userProfile.download")} <i className="fas fa-download"></i>
                            </a>
                        </h4>
                        <label htmlFor={filetype.uuid} className="form-label">
                            {filetype.libelle}
                        </label>
                        <input
                            onChange={(event) => handleFileUpload(filetype, event)}
                            className="form-control"
                            type="file"
                            required={filetype.isRequired}
                            id={filetype.uuid}
                            accept={fileformat}
                        />
                    </div>
                )}
                {!filetype.uploaded && (
                    <div className="mb-3">
                        <label htmlFor={filetype.uuid} className="form-label">
                            {filetype.libelle}
                        </label>
                        <input
                            onChange={(event) => handleFileUpload(filetype, event)}
                            className="form-control"
                            type="file"
                            required={filetype.isRequired}
                            id={filetype.uuid}
                            accept={fileformat}
                        />
                    </div>
                )}
            </div>
        );
    });

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-lg-5">
                    <div className="card">
                        <div className="card-body">
                            {personinfo?.personType === "Student" && <StudentInfo personinfo={personinfo} headers={headers} t={t} />}
                            {personinfo?.personType === "Lecturer" && <LecturerInfo personinfo={personinfo} headers={headers} t={t} />}
                            {personinfo?.personType === "Agent" && <AgentInfo personinfo={personinfo} headers={headers} t={t} />}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-7">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="row">
                                {filetypeOutPut}
                                <button className="btn btn-success mt-3">{t("userProfile.submit")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfile;
