import React from "react";
import Select from 'react-select';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

export const BasicInfo = ({ formStep, countries, control, register, errors, errorMessageRender }) => {
    const { t } = useTranslation();

    const countriesArray = Object.values(countries);
    const countriesOptions = countriesArray.map((d) => (
        { value: d.id, label: `${d.flag} ${d.en}` }
    ));
    const preselectedValue = { value: 157, label: "🇳🇬 Nigeria" };
    const callCodeOptions = countriesArray.map((d, index) => (
        { value: d.id, label: `${d.flag} ${d.callcode}` }
    ));
    const preselectedCallcode = { value: 157, label: "🇳🇬 +234" };

    return (
        <div className={formStep !== 1 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">{t('registration_form.basic_info')}</h3>
            <p className="small text-secondary text-center">{t('registration_form.basic_info_desc')}</p>
            <p className="text-end dynmatxt">{t('registration_form.step')} <small className="fw-bold">{formStep}/4</small></p>

            <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="lastname">{t('registration_form.surname')} <span className="text-danger">*</span></label>
                    <input id="lastname" {...register("lastname", {
                        required: {
                            value: true,
                            message: t('registration_form.surname_error')
                        }
                    })} className="form-control" required="required" type="text" placeholder="" />
                    {errors.lastname && errorMessageRender(errors.lastname.message)}
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="firstname">{t('registration_form.other_names')} <span className="text-danger">*</span></label>
                    <input id="firstname" {...register("firstname", {
                        required: {
                            value: true,
                            message: t('registration_form.other_names_error')
                        }
                    })} name="firstname" className="form-control" required="required" type="text" placeholder="" />
                    {errors.firstname && errorMessageRender(errors.firstname.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="dateofbirth" className="active">{t('registration_form.date_of_birth')} <span className="text-danger">*</span></label>
                    <input id="dateofbirth" {...register("dateofbirth", {
                        required: {
                            value: true,
                            message: t('registration_form.dob_error')
                        }
                    })} className="form-control" type="date" placeholder="mm/dd/yyyy" />
                    {errors.dateofbirth && errorMessageRender(errors.dateofbirth.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="email">{t('registration_form.email')} <span className="text-danger">*</span></label>
                    <input id="email" className="form-control" {...register("emailadress", {
                        required: {
                            value: true,
                            message: t('registration_form.email_error')
                        }
                    })} type="email" placeholder="" />
                    {errors.emailadress && errorMessageRender(errors.emailadress.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="telephonenumber" className="active">{t('registration_form.telephone')} <span className="text-danger">* <small>{t('registration_form.no_spaces')}</small></span></label>
                    <div className="input-group mb-3">
            <span className="input-group-text p-0">
              <Controller name="telephonecallcode" control={control} defaultValue={preselectedCallcode} render={({ field }) => (
                  <Select {...field} options={callCodeOptions} />
              )} />
            </span>
                        <input type="text" name="telephonenumber" {...register("telephonenumber", {
                            required: {
                                value: true,
                                message: t('registration_form.telephone_error')
                            }
                        })} className="form-control numberField" placeholder={t('registration_form.without_country_code')} />
                    </div>
                    {errors.telephonenumber && errorMessageRender(errors.telephonenumber.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="whatsappnumber" className="active">{t('registration_form.whatsapp')} <span className="text-danger">* <small>{t('registration_form.no_spaces')}</small></span></label>
                    <div className="input-group mb-3">
            <span className="input-group-text p-0">
              <Controller name="whatsappcallcode" control={control} defaultValue={preselectedCallcode} render={({ field }) => (
                  <Select {...field} options={callCodeOptions} />
              )} />
            </span>
                        <input type="text" {...register("whatsappnumber", {
                            required: {
                                value: true,
                                message: t('registration_form.whatsapp_error')
                            }
                        })} className="form-control numberField" placeholder={t('registration_form.without_country_code')} />
                    </div>
                    {errors.whatsappnumber && errorMessageRender(errors.whatsappnumber.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="nationality">{t('registration_form.country_of_origin')} <span className="text-danger">*</span></label>
                    <Controller name="country" control={control} defaultValue={preselectedValue} render={({ field }) => (
                        <Select {...field} options={countriesOptions} />
                    )} />
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="gender">{t('registration_form.gender')} <span className="text-danger">*</span></label>
                    <select className="form-control" {...register("gender", {
                        required: {
                            value: true,
                            message: t('registration_form.gender_error')
                        }
                    })} id="gender">
                        <option value=""></option>
                        <option value="1">{t('registration_form.male')}</option>
                        <option value="0">{t('registration_form.female')}</option>
                    </select>
                    {errors.gender && errorMessageRender(errors.gender.message)}
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="adress">{t('registration_form.address')} <span className="text-danger">*</span></label>
                    <input id="adress" {...register("address", {
                        required: {
                            value: true,
                            message: t('registration_form.address_error')
                        }
                    })} className="form-control" type="textarea" />
                    {errors.address && errorMessageRender(errors.address.message)}
                </div>
            </div>
        </div>
    );
};

export default BasicInfo;
