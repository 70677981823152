import React from "react";
import {Context} from "../../../context/Contexts";
import API from "../../../api";
import {useSelector} from "react-redux";
import TopMeunSection from "../../Blocks/TopMeunSection";
import DataTable from "react-data-table-component";
import FeeTable from "./FeeTable";
import {useTranslation} from "react-i18next";

export const FeesContent = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const { t } = useTranslation();  // Translation hook
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [Fees, setFees] = React.useState([])
    const [pending, setPending] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    React.useEffect(() => {
        const timeout = setTimeout(() => {
                const req = async (e) => {

                    try {
                        const res = await API.post('fees_transactions', {}, {headers})
                        setFees(res.data);
                        setPending(false);
                    } catch (e) {

                    }
                }
                req()
            }
            , 400);
        return () => clearTimeout(timeout);
    }, []);
    const apptheme = useSelector(state => state.user.userTheme);
    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };
    const filteredData = Fees.filter((row) =>
        row.type.toLowerCase().includes(searchText.toLowerCase()) ||
        row.invoiceNo.toLowerCase().includes(searchText.toLowerCase())
    );
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">

                                <div className="mt-4">
                                    <input
                                        type="search"
                                        className="form-control mb-3"
                                        value={searchText}
                                        onChange={handleSearch}
                                        placeholder={t('FeesTransaction.search_placeholder')}
                                    />
                                    <DataTable
                                        responsive
                                        striped
                                        theme={apptheme ? "dark" : undefined} // Fixed theme assignment
                                        direction="auto"
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        selectableRows
                                        columns={FeeTable(null,t)}
                                        progressPending={pending}
                                        data={filteredData}
                                        pagination
                                        noDataComponent={t('FeesTransaction.no_data')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FeesContent;
