import API from "../../../api";
import React from "react";

export const PaymentTable = (t) => {
    const buttons = [
        {
            label: '',
            className: 'btn btn-sm btn-success',
            onClick: () => {},
        },
    ];

    const [isLoadingPdf, setIsLoadingPdf] = React.useState({status: false, rowReference: ''});

    function downloadPdf(url, reference) {
        setIsLoadingPdf((prevState) => ({
            ...prevState,
            status: true,
            rowReference: reference,
        }));

        const filename = 'RECEIPT-' + reference + ".pdf";

        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', filename);

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(e => console.error('Error downloading the file: ', e))
            .finally(() => {
                setIsLoadingPdf((prevState) => ({
                    ...prevState,
                    status: false,
                    rowReference: '',
                }));
            });
    }

    const baseURL = API.defaults.baseURL.slice(0, -4);
    return [
        {
            cell: (row) => (
                <div>
                    {isLoadingPdf.rowReference === row.reference && isLoadingPdf.status ? (
                        <i className="fas fa-spinner fa-spin-pulse me-3"></i>
                    ) : (
                        <i className="d-none"></i>
                    )}

                    {buttons.map((button, index) => (
                        <button
                            disabled={isLoadingPdf.rowReference === row.reference && isLoadingPdf.status}
                            onClick={() =>
                                downloadPdf(
                                    `${baseURL}website_api/payments/download-payment-reciept/${row.reference}`,
                                    row.reference
                                )
                            }
                            type="button"
                            key={index}
                            className={button.className}
                            target="_blank"
                        >
                            {button.label} <i className="fas fa-print"></i>
                        </button>
                    ))}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: t('PaymentTable.reference'),
            selector: row => row.reference,
            sortable: true,
        },
        {
            name: t('PaymentTable.amount'),
            selector: row => `${row.amount} ${row.currency}`,
            sortable: true,
        },
    ];
};

export default PaymentTable;
