import React from "react";

const NotificationTable = ({ t }) => {
	const formattedDate = (dateString) => {
		return new Date(dateString).toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		});
	};

	return [
		{
			name: t("notifications.notificationTable.object"),
			selector: (row) => row.object,
			sortable: true,
		},
		{
			name: (
				<>
					{t("notifications.notificationTable.read")} <i className="fas fa-envelope-open px-1"></i> |
					{t("notifications.notificationTable.unread")} <i className="fas fa-envelope px-1"></i>
				</>
			),
			selector: (row) => row.isRead ? (
				<><i className="fas fa-envelope-open fa-lg"></i></>
			) : (
				<><i className="fas fa-envelope fa-lg"></i></>
			),
			sortable: true,
		},
		{
			name: t("notifications.notificationTable.description"),
			selector: (row) => row.description,
			sortable: true,
		},
		{
			name: t("notifications.notificationTable.date"),
			selector: (row) => formattedDate(row.created),
			sortable: true,
		},
	];
};

export default NotificationTable;