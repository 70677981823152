import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderWithLanguageDropdown = () => {
    const { t,i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
    };

    return (
        <div className="header-container">
            {/* Language dropdown on the left */}
            <div className="dropdown language-select">
                <button
                    className="btn btn-secondary dropdown-toggle language-selector"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {currentLanguage.toUpperCase()}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li>
                        <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                            {t('english')}
                        </button>
                    </li>
                    <li>
                        <button className="dropdown-item" onClick={() => changeLanguage('fr')}>
                            {t('french')}
                        </button>
                    </li>
                </ul>
            </div>

            <img src=""
                 alt="Portal_logo"
                 aria-label="Portal logo"
                 className="css-134k5z9"/>
        </div>
    );
};

export default HeaderWithLanguageDropdown;
