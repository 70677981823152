import React from "react";
import API from "../../api";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";
import DataTable from 'react-data-table-component';
import {useSelector} from "react-redux";
import CourseTable from "./CourseTable";
import CourseResgiterModal from "./CourseResgiterModal";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";
import {useTranslation} from "react-i18next";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context);
    const { t } = useTranslation();  // Translation hook

    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [courselistchange, setCourselistchange] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [courseList, setCourseList] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const req = async () => {
                try {
                    let url = 'student_course';
                    if (personinfo.personType === 'Lecturer') {
                        url = 'lecturer_assigned_course';
                    }
                    const res = await API.post(url, {}, {headers});
                    setCourseList(res.data);
                    setPending(false);
                } catch (e) {
                    errorAlert(t('course.error_title'), t('course.error_message'));
                }
            };
            req();
        }, 300);
        return () => clearTimeout(timeout);
    }, [courselistchange]);

    const apptheme = useSelector(state => state.user.userTheme);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleAutoRegisterCourse = () => {
        const req = async () => {
            try {
                const res = await API.post('student_register_course_auto', {}, {headers});
                successAlert(t('course.success_title'), res.data);
                setPending(true);
                handleCourselistchange();
            } catch (e) {
                errorAlert(t('course.auto_registration_error_title'), t('course.auto_registration_error_message'));
            }
        };
        req();
    };

    const handleCourselistchange = () => {
        setCourselistchange(prevState => (!prevState));
    };

    const filteredData = courseList.filter((row) =>
        row.title.toLowerCase().includes(searchText.toLowerCase()) ||
        row.code.toLowerCase().includes(searchText.toLowerCase())
    );

    const [modalShow, setModalShow] = React.useState(false);
    const handleToggleModal = () => {
        setModalShow(prevState => (!prevState));
    };

    const ExpandedComponent = ({data}) => (
        <div>
            <p>{t('course.lecturer_in_charge')}: <b>{data.firstname} {data.lastname}</b></p>
            <p>{t('course.description')}: <div dangerouslySetInnerHTML={{__html: data.description}}></div></p>
        </div>
    );

    const baseURL = API.defaults.baseURL.slice(0, -4);

    const handleDownloadCourseSheet = () => {
        const externalUrl = `${baseURL}website_api/download_course_sheet/${personinfo.id}`;
        const link = document.createElement('a');
        link.href = externalUrl;
        link.download = 'resource.pdf'; // Specify the desired file name
        link.click();
    };

    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h3>{t('course.my_course')}</h3>
                                    {personinfo?.personType === 'Lecturer' && (
                                        <a href="/list-scripts" className="btn btn-sm btn-success text-uppercase d-flex align-content-center">
                                            {t('course.mark_scripts')}
                                        </a>
                                    )}
                                    {personinfo?.personType === 'Student' && (
                                        <div className="text-end">
                                            {courseList.length === 0 ? (
                                                <button onClick={handleAutoRegisterCourse} className="btn btn-sm btn-outline-success text-uppercase mx-1">
                                                    {t('course.auto_register')}
                                                </button>
                                            ) : (
                                                <button onClick={handleToggleModal} className="btn btn-sm btn-success text-uppercase mx-1">
                                                    {t('course.register_more')}
                                                </button>
                                            )}
                                            <button onClick={handleDownloadCourseSheet} type="button" className="btn btn-sm btn-danger mx-1">
                                                {t('course.print_coursesheet')} <i className="fas fa-download"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {courseList.length !== 0 && (
                                    <CourseResgiterModal headers={headers} modalShow={modalShow}
                                                         setModalShow={setModalShow}
                                                         apptheme={apptheme}
                                                         handleCourselistchange={handleCourselistchange}
                                                         t={t}/>
                                )}
                                <div className="mt-4">
                                    <input
                                        type="search"
                                        className="form-control mb-3"
                                        value={searchText}
                                        onChange={handleSearch}
                                        placeholder={t('course.search_placeholder')}
                                    />
                                    <DataTable
                                        responsive
                                        striped
                                        theme={apptheme ? "dark" : undefined}
                                        direction="auto"
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                        columns={CourseTable(personinfo,t)}
                                        progressPending={pending}
                                        data={filteredData}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Content;
