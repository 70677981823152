import API from "../../../api";
import { useTranslation } from "react-i18next";

export const ProfileArea = (props) => {
    const baseURL = API.defaults.baseURL.slice(0, -4);
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const languages = [
        { code: "en", label: "EN" },
        { code: "fr", label: "FR" },
        // Add more languages here if needed
    ];

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("i18nextLng", lng);
    };

    return (
        <>
            {props.profileImgCode !== null ? (
                <img src={`${baseURL}${props.profileImgCode}`} alt="ProfileImage" />
            ) : (
                <div className="icon-frame">
                    <i className="las la-user"></i>
                </div>
            )}
            <h4 className="profileName">{props.alisa}</h4>
            <h5>
                <span className="badge">{props.personType}</span>
            </h5>
            {languages.map((lang) => (
                <button
                    key={lang.code}
                    className={`btn btn-sm ${currentLanguage === lang.code ? 'btn-primary' : 'btn-outline-light text-white'}`}
                    onClick={() => changeLanguage(lang.code)}
                >
                    {lang.label}
                </button>
            ))}
        </>
    );
};

export default ProfileArea;
