import setPageTitle from "../../services/TitleService";
import React from "react";
import {updateUserTheme} from "../../redux/userRedux";
import {useDispatch, useSelector} from "react-redux";
import '../../components/Regsitration/registration.scss';
import BasicInfo from "../../components/Regsitration/BasicInfo";
import AcademicInfo from "../../components/Regsitration/AcademicInfo";
import GuardianInfo from "../../components/Regsitration/GuardianInfo";
import FIleUpload from "../../components/Regsitration/FIleUpload";
import API from "../../api";
import {useForm} from "react-hook-form";
import errorAlert from "../../services/AlertS/error";
import {useParams} from "react-router-dom";
import SummaryInfo from "../../components/Regsitration/SummaryInfo";
import HeaderWithLanguageDropdown from "./HeaderWithLanguageDropdown";
import {useTranslation} from "react-i18next"; // Importing i18n

export const RegistrationForm = () => {
    const {t, i18n} = useTranslation(); // Using the translation hook
    const registerForm = React.useRef(null);

    setPageTitle('Registration Form');
    const currentLanguage = i18n.language;
    const dispatchs = useDispatch();
    const theme = useSelector(state => state.user.userTheme);
    if (theme) {
        document.body.classList.add('dark-theme');
    } else {
        document.body.classList.remove('dark-theme');
    }
    const handleThemeToggle = () => {
        dispatchs(updateUserTheme(!theme))
    };
    const {
        watch, register, handleSubmit, control, setValue
        , formState: {errors, isValid}
    } = useForm({mode: 'all'});
    const [department, setDepartment] = React.useState([]);
    const [level, setLevel] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [programs, setPrograms] = React.useState([]);
    const [admissionType, setAdmissionType] = React.useState([]);
    const [schoolDiscovery, setSchoolDiscovery] = React.useState([]);
    const [certifcates, setCertifcates] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [studentData, setStudentData] = React.useState(null);
    const [isLoading, setLoading] = React.useState(false);
    const [personfileTypes, setPersonfileTypes] = React.useState([]);

    React.useEffect(() => {

        const req = async (e) => {

            try {
                const resFiles = await API.post('get_student_files', {}, {})
                setPersonfileTypes(resFiles.data)
                const res = await API.post('registraion_form', {}, {});
                setDepartment(res.data.department)
                setLevel(res.data.level)
                setCountries(res.data.countries)
                setPrograms(res.data.programs)
                setAdmissionType(res.data.admissionType)
                setSchoolDiscovery(res.data.schoolDiscovery)
                setCertifcates(res.data.certification)
            } catch (e) {

            }
        }
        req()

    }, []);

    const [formStep, setFormStep] = React.useState(1)
    const checkEmptyFileFields = () => {
        let i = 0;
        personfileTypes.forEach((filetype) => {
            if (filetype.isRequired) {
                i++
            }
        })
        if (studentData?.fileUploads) {

            if (Object.keys(studentData?.fileUploads).length === 0 || Object.keys(studentData?.fileUploads).length < i) {
                errorAlert(t('registration_form.no_files_uploaded_alert_title'), t('registration_form.no_files_uploaded_alert_desc'));
                return false;
            }
        }
        return true;
    }

    const nextStep = (event) => {
        setFormStep(prevState => prevState + 1);
        setFields()
    };
    const prevStep = (e) => {
        setFormStep(prevState => prevState - 1);
        setFields()
    };
    const {sponsorCode} = useParams();
    const errorMessageRender = (message) => {
        return (
            <div className="alert alert-danger alert-dismissible fade show mt-2 p-1" role="alert">
                {message}
                <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        )
    }

    React.useEffect(() => {
        setValue("fileUploads", uploadFiles);
    }, [uploadFiles]);

    const setFields = async () => {
        const form = registerForm.current;
        const levelValue = form.elements.level ? form.elements.level.value : '';
        const admissionTypeValue = form.elements.admissionType ? form.elements.admissionType.value : '';
        const programValue = form.elements.program ? form.elements.program.value : '';
        const noofsitting = form.elements.noofsitting ? form.elements.noofsitting.value : '';

        setValue("level", levelValue);
        setValue("admissionType", admissionTypeValue);
        setValue("program", programValue);
        setValue("noofsitting", noofsitting);
        if (sponsorCode) {
            setValue("sponsorCode", sponsorCode);
            setValue("schoolDiscovery", 'Sponsor');
        }

        setValue("fileUploads", uploadFiles);
        const formdata = watch();
        setStudentData(formdata)
    }
    const handleFormSubmit = async (data, e) => {
        e.preventDefault();
        if (!checkEmptyFileFields()) {
            return;
        }
        !isValid && errorAlert(t('registration_form.form_invalid_title'), t('registration_form.form_invalid_desc'));
        setLoading(true)
        setFields()
        data.sectionType = currentLanguage;
        setStudentData(data)
        const req = async (e) => {

            try {
                const res = await API.post('registion_save', studentData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                if (res.data === 'saved') {
                    window.location.replace(`/application-form/status/successful`)
                }
                if (res.data === 'existing') {
                    window.location.replace(`/application-form/status/existing`)
                }
            } catch (e) {
                if (e.response.data === 'existing') {
                    window.location.replace(`/application-form/status/existing`)
                }
                if (e.response.data === 'file_format_error') {
                    errorAlert(t('registration_form.file_format_error_title'), t('registration_form.file_format_error_desc'));
                }
                setLoading(false)
                errorAlert(t('registration_form.registration_error_title'), t('registration_form.registration_error_desc'));
            }
        }
        isValid && req()
    }
    return (
        <div className="rpcp111">
            <div className="h47o">
                <div className="a8x12dwz">
                    <div className="formContainer">
                        <div className="css-jz6d9T">
                            <HeaderWithLanguageDropdown/>
                            <div className="d-flex justify-content-between my-2">
                                <div>
                                    <small
                                        className="me-3 switchTheme cursor"
                                        onClick={handleThemeToggle}>
                                        {t('registration_form.toggle_dark_mode')} <i className="las la-sun la-1x"></i>
                                    </small>
                                </div>
                                <div>
                                    <a href="/login"
                                       className="me-3 text-decoration-none primary-color fw-bold small">
                                        {t('registration_form.go_to_login')} <i
                                        className="las la-external-link-alt la-1x"></i>
                                    </a>
                                </div>
                            </div>
                            <form ref={registerForm} autoComplete='off' className="position-relative"
                                  onSubmit={handleFormSubmit}>
                                {formStep >= 1 &&
                                    <BasicInfo formStep={formStep} control={control} register={register}
                                               errors={errors}
                                               errorMessageRender={errorMessageRender} countries={countries}/>}

                                {formStep >= 2 &&
                                    <AcademicInfo registerForm={registerForm} formStep={formStep} control={control}
                                                  register={register}
                                                  errors={errors} department={department} certifcates={certifcates}
                                                  level={level} programs={programs} sponsorCode={sponsorCode}
                                                  errorMessageRender={errorMessageRender}
                                                  admissionType={admissionType} schoolDiscovery={schoolDiscovery} currentLanguage={currentLanguage}/>}

                                {formStep >= 3 &&
                                    <FIleUpload setValue={setValue} formStep={formStep} register={register}
                                                errors={errors} personfileTypes={personfileTypes}
                                                errorMessageRender={errorMessageRender}
                                                uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} currentLanguage={currentLanguage}/>}
                                {formStep >= 4 &&
                                    <GuardianInfo formStep={formStep} control={control} countries={countries}
                                                  register={register} errors={errors}
                                                  errorMessageRender={errorMessageRender}/>}
                                {formStep >= 5 &&
                                    <SummaryInfo formStep={formStep} studentData={studentData} level={level}
                                                 programs={programs} errors={errors} admissionType={admissionType}
                                                 errorMessageRender={errorMessageRender}/>}
                                <div className="d-sm-block d-md-flex justify-content-between text-center mt-3">

                                    {formStep > 1 && formStep <= 5 && (
                                        <button type="button" className="btn btn-secondary" onClick={prevStep}>
                                            {t('registration_form.previous_step')}
                                        </button>
                                    )}
                                    {formStep <= 4 &&
                                        <button type="button" className="btn nextbtn" onClick={nextStep}>
                                            {formStep < 4 ? t('registration_form.next_step') : t('registration_form.view_summary')}
                                        </button>}
                                    {formStep === 5 &&
                                        <button type="submit" onClick={handleSubmit(handleFormSubmit)}
                                                className="btn btn-outline-warning dynmatxt">
                                            {t('registration_form.submit_form')} {isLoading &&
                                            <i className="fas fa-circle-notch fa-spin"></i>}
                                        </button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationForm;
