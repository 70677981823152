import {Modal} from "react-bootstrap";
import React from "react";

export const DescriptionModal = ({asset, handleToggleDescptionModal, modalDescptionShow, translate}) => {
    return (
        <Modal show={modalDescptionShow} onHide={handleToggleDescptionModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="d-flex w-100">
                        <span>{translate('library.asset.descriptionTitle')}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {asset.note === null ? (
                    <h3 className="dynmatxt text-center">{translate('library.asset.noDescription')}</h3>
                ) : (
                    <div className="dynmatxt" dangerouslySetInnerHTML={{ __html: asset.note }}/>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default DescriptionModal;