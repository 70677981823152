import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

export const GuardianInfo = ({ formStep, countries, register, control, errors, errorMessageRender }) => {
    const { t } = useTranslation();

    const countriesArray = Object.values(countries);
    const callCodeOptions = countriesArray.map((d) => (
        { value: d.id, label: `${d.flag} ${d.callcode}` }
    ));
    const preselectedValue = { value: 157, label: "🇳🇬 +234" };

    return (
        <div className={formStep !== 4 ? "d-none" : undefined}>
            <h3 className="primary-color text-center text-uppercase">
                {t('registration_form.guardian_info')} {formStep}/4
            </h3>
            <p className="small text-secondary text-center">
                {t('registration_form.guardian_info_desc')}
            </p>
            <p className="text-end dynmatxt">{t('registration_form.step')} <small className="fw-bold">{formStep}/4</small></p>

            <div className="row">
                <div className="col-sm-12 mb-4">
                    <label htmlFor="gfullname" className="active">{t('registration_form.guardian_fullname')} <span className="text-danger">*</span></label>
                    <input id="gfullname" className="form-control" {...register("gfullname", {
                        required: {
                            value: true,
                            message: t('registration_form.guardian_fullname_error')
                        }
                    })} type="text" />
                    {errors.gfullname && errorMessageRender(errors.gfullname.message)}
                </div>

                <div className="col-sm-12 mb-4">
                    <label htmlFor="gnumber" className="active">{t('registration_form.guardian_phone')} <span className="text-danger">* <small>{t('registration_form.no_spaces')}</small></span></label>
                    <div className="input-group mb-3">
            <span className="input-group-text p-0" id="basic-addon1">
              <Controller
                  name="gtelephonecallcode"
                  control={control}
                  defaultValue={preselectedValue}
                  render={({ field }) => (
                      <Select {...field} options={callCodeOptions} />
                  )}
              />
            </span>
                        <input type="text" {...register("gnumber", {
                            required: {
                                value: true,
                                message: t('registration_form.guardian_phone_error')
                            }
                        })} className="form-control numberField" placeholder={t('registration_form.without_country_code')} />
                    </div>
                    {errors.gnumber && errorMessageRender(errors.gnumber.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="gemail">{t('registration_form.guardian_email')}</label>
                    <input id="gemail" className="form-control" name="gemail" type="email" {...register("gemail", {
                        required: {
                            message: t('registration_form.guardian_email_error')
                        }
                    })} />
                </div>

                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="grelation">{t('registration_form.guardian_relation')} <span className="text-danger">*</span></label>
                    <select name="grelation" className="form-control" id="grelation" required="" {...register("grelation", {
                        required: {
                            value: true,
                            message: t('registration_form.guardian_relation_error')
                        }
                    })}>
                        <option value=""></option>
                        <option>{t('registration_form.father')}</option>
                        <option>{t('registration_form.mother')}</option>
                        <option>{t('registration_form.uncle')}</option>
                        <option>{t('registration_form.aunty')}</option>
                        <option>{t('registration_form.brother')}</option>
                        <option>{t('registration_form.sister')}</option>
                        <option>{t('registration_form.cousin')}</option>
                        <option>{t('registration_form.husband')}</option>
                        <option>{t('registration_form.legal_tutor')}</option>
                    </select>
                    {errors.grelation && errorMessageRender(errors.grelation.message)}
                </div>

                <div className="col-sm-12">
                    <label htmlFor="gaddress" className="active">{t('registration_form.guardian_address')}</label>
                    <input id="gaddress" className="form-control" name="gaddress" type="text" {...register("gaddress", {
                        required: {
                            message: t('registration_form.guardian_address_error')
                        }
                    })} />
                </div>
            </div>
        </div>
    );
};

export default GuardianInfo;
