import React from "react";
import Select from 'react-select';
import {Controller} from "react-hook-form";
import {useTranslation} from 'react-i18next';

export const AcademicInfo = ({
                                 errorMessageRender,
                                 formStep,
                                 control,
                                 errors,
                                 department,
                                 certifcates,
                                 level,
                                 sponsorCode,
                                 programs,
                                 admissionType,
                                 schoolDiscovery, currentLanguage
                             }) => {

    const {t} = useTranslation();

    const departmentArray = Object.values(department);
    const departmentOptions = departmentArray
        .filter((d) => currentLanguage === d.sectionType)
        .map((d) => ({
            value: d.uuid,
            label: `${d.title.toUpperCase()}`,
            programid: d.programid
        }));


    const certificateArray = Object.values(certifcates);
    const certificateOptions = certificateArray.map((d) => ({
        value: d.id,
        label: currentLanguage === "en" ? d.libelle : currentLanguage === "fr" ? d.libelleFr : d.libelle
    }));

    const admissionTypeOptions = admissionType.map((d, index) => (
        <div key={index} className="form-check form-switch">
            <input
                className="form-check-input"
                role="switch"
                aria-checked="false"
                name="admissionType"
                type="radio"
                value={d.id}
                id={`admissionType-${index}`}
                defaultChecked={index === 0}
                required
            />
            <label className="form-check-label" htmlFor={`admissionType-${index}`}>
                {currentLanguage === "en" ? d.libelle : currentLanguage === "fr" ? d.libelleFr : d.libelle}
            </label>
        </div>
    ));

    const levelOptions = level.map((d, index) => (
        <div key={index} className="form-check form-switch">
            <input
                className="form-check-input"
                role="switch"
                aria-checked="false"
                name="level"
                type="radio"
                value={d.id}
                id={`level-${index}`}
                defaultChecked={index === 0}
                required
            />
            <label className="form-check-label" htmlFor={`level-${index}`}>
                {currentLanguage === "en" ? d.title : currentLanguage === "fr" ? d.titleFr : d.title}
            </label>
        </div>
    ));

    const programsOptions = Object.values(programs).map((d, index) => (
        <div key={index} className="form-check form-switch">
            <input
                className="form-check-input"
                role="switch"
                aria-checked="false"
                name="program"
                type="radio"
                value={d.id}
                id={`program-${index}`}
                defaultChecked={index === 0}
                required
            />
            <label className="form-check-label" htmlFor={`program-${index}`}>
                {currentLanguage === "en" ? d.libelle : currentLanguage === "fr" ? d.libelleFr : d.libelle}
            </label>
        </div>
    ));

    const schoolDiscoveryArray = Object.values(schoolDiscovery);
    const schoolDiscoveryOptions = schoolDiscoveryArray.map((d) => ({
        value: d.id,
        label: currentLanguage === "en" ? d.libelle : currentLanguage === "fr" ? d.libelleFr : d.libelle
    }));

    return (
        <div className={formStep !== 2 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">{t('registration_form.academic_info')}</h3>
            <p className="small text-secondary text-center">
                {t('registration_form.academic_info_desc')}
            </p>
            <p className="text-end dynmatxt">{t('registration_form.step')} <small
                className="fw-bold">{formStep}/4</small></p>

            <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="">{t('registration_form.enrollment_type')} <span
                        className="text-danger">*</span></label>
                    <div className="d-md-flex justify-content-md-between">
                        <Controller
                            name="admissionType"
                            control={control}
                            rules={{required: t('registration_form.enrollment_type_error')}}
                            render={({field}) => <>{admissionTypeOptions}</>}
                        />
                        {errors.admissionType && errorMessageRender(errors.admissionType.message)}
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="">{t('registration_form.level')} <span className="text-danger">*</span></label>
                    <div className="d-md-flex justify-content-md-between">
                        <Controller
                            name="level"
                            control={control}
                            rules={{required: t('registration_form.level_error')}}
                            render={({field}) => <>{levelOptions}</>}
                        />
                        {errors.lastname && errorMessageRender(errors.lastname.message)}
                    </div>
                </div>

                <div className="col-sm-12  mb-4">
                    <label htmlFor="discipline">{t('registration_form.program_type')} <span
                        className="text-danger">*</span></label>
                    <div className="d-md-flex justify-content-md-between">
                        <Controller
                            name="program"
                            control={control}
                            render={({field}) => <>{programsOptions}</>}
                        />
                    </div>
                    {errors.program && errorMessageRender(errors.program.message)}
                </div>

                <div className="col-sm-12 mb-4">
                    <label htmlFor="department">{t('registration_form.department')} <span
                        className="text-danger">*</span></label>
                    <Controller
                        name="department"
                        control={control}
                        rules={{required: t('registration_form.department_error')}}
                        render={({field}) => (
                            <Select {...field} options={departmentOptions}/>
                        )}
                    />
                    {errors.department && errorMessageRender(errors.department.message)}
                </div>

                <div className="col-sm-12 col-md-7 mb-4">
                    <label htmlFor="department">{t('registration_form.certificate')} <span
                        className="text-danger">*</span></label>
                    <Controller
                        name="certificate"
                        control={control}
                        rules={{required: t('registration_form.certificate_error')}}
                        render={({field}) => (
                            <Select {...field} options={certificateOptions}/>
                        )}
                    />
                    {errors.certificate && errorMessageRender(errors.certificate.message)}
                </div>

                <div className="col-sm-12 col-md-5 mb-4">
                    <label htmlFor="noofsitting">{t('registration_form.no_of_sittings')}</label>
                    <select name="noofsitting" className="form-control" id="" required="" aria-required="true">
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </div>

                {!sponsorCode && (
                    <div className="col-sm-12 col-md-12 mb-4">
                        <label htmlFor="noofsitting">{t('registration_form.school_discovery')}</label>
                        <Controller
                            name="schoolDiscovery"
                            control={control}
                            render={({field}) => (
                                <Select {...field} options={schoolDiscoveryOptions} isClearable={true}/>
                            )}
                        />
                        {errors.schoolDiscovery && errorMessageRender(errors.schoolDiscovery.message)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AcademicInfo;
