import TopMeunSection from "../../Blocks/TopMeunSection";
import React from "react";
import API from "../../../api";
import { Context } from "../../../context/Contexts";
import Slider from "react-slick";
import WalletCard from "../../Dashboard/WalletCard";
import errorAlert from "../../../services/AlertS/error";
import successAlert from "../../../services/AlertS/sucess";
import { useTranslation } from "react-i18next";

export const TransferFormContent = ({ personinfo }) => {
    const { t } = useTranslation();  // Translation hook

    const initForm = {
        note: "",
        wallet: "",
        personCode: "",
        personType: "",
        amount: "",
        optcode: "",
    };
    const [formData, setFormData] = React.useState(initForm);
    const [isLoading, setIsLoading] = React.useState(false);
    const [userwallets, setUserwallets] = React.useState([]);
    const [ChangeWalletState, setChangeWalletState] = React.useState(true);

    const { user } = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user?.token}`,
    };

    const resetForm = () => {
        setChangeWalletState((prevState) => {
            return !prevState;
        });
        setFormData(initForm);
        setIsLoading((prevState) => !prevState);
    };

    function handleChange(event) {
        const { name, value, type, checked } = event.target;
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: type === "checkbox" ? checked : value,
            };
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const req = async (e) => {
            try {
                await API.post("transferfund", { formData }, { headers });
                successAlert(t("TransferForm.successMessage"), t("TransferForm.operationSuccessful"));
                resetForm();
            } catch (e) {
                errorAlert(t("TransferForm.transactionError"), e.response.data);
                resetForm();
            }
        };
        req();
    };

    const walletSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    React.useEffect(() => {
        API.post("wallet_info", {}, { headers }).then((res) => setUserwallets(res.data));
    }, [ChangeWalletState]);

    const walletElement = userwallets.length > 1 ? (
        <Slider {...walletSettings}>
            {userwallets.map((item, index) => (
                <WalletCard key={index} {...item} />
            ))}
        </Slider>
    ) : (
        userwallets.map((item, index) => <WalletCard key={index} {...item} />)
    );

    const walletOptions = userwallets.map((wallet) => (
        <option key={wallet.uuid} value={wallet.uuid}>
            {wallet.libelle} -> {parseInt(wallet.balance).toLocaleString()}
        </option>
    ));

    return (
        <div className="content">
            <TopMeunSection personinfo={personinfo} />
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12 col-lg-6 offset-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <h3 className="mb-4">
                                        {t("TransferForm.transferFunds")}
                                        <div className="d-inline float-end">
                                            <a href="/transaction-history" className="notificationboard-viewmore">
                                                <span>{t("TransferForm.viewTransactions")}</span>{" "}
                                                <i className="fas fa-fas fa-list"></i>
                                            </a>
                                        </div>
                                    </h3>
                                    <small>{t("TransferForm.myWallets")}</small>
                                    {walletElement}
                                    <hr />

                                    <label className="control-label dynmatxt">
                                        {t("TransferForm.recieverType")}
                                    </label>
                                    <div className="d-flex justify-content-between w-100 mt-4 mb-3">
                                        <div>
                                            <input
                                                type="radio"
                                                id="transfer_personType_0"
                                                name="personType"
                                                required="required"
                                                onChange={handleChange}
                                                checked={formData.personType === "student"}
                                                value="student"
                                            />
                                            <label htmlFor="transfer_personType_0" className="required ps-2">
                                                {t("TransferForm.student")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="transfer_personType_1"
                                                name="personType"
                                                checked={formData.personType === "lecturer"}
                                                required="required"
                                                onChange={handleChange}
                                                value="lecturer"
                                            />
                                            <label htmlFor="transfer_personType_1" className="required ps-2">
                                                {t("TransferForm.lecturer")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="recieverCode"
                                            name="personCode"
                                            required="required"
                                            value={formData.personCode}
                                            onChange={handleChange}
                                            placeholder="&nbsp;"
                                        />
                                        <label htmlFor="recieverCode">{t("TransferForm.enterMatricCode")}</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control numberField"
                                            id="recieverCode"
                                            name="amount"
                                            required="required"
                                            value={formData.amount}
                                            onChange={handleChange}
                                            placeholder="&nbsp;"
                                        />
                                        <label htmlFor="recieverCode">{t("TransferForm.amount")}</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select"
                                            id="wallet"
                                            required="required"
                                            name="wallet"
                                            value={formData.wallet}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>
                                                {t("TransferForm.selectWallet")}
                                            </option>
                                            {walletOptions}
                                        </select>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <textarea
                                            className="form-control"
                                            name="note"
                                            placeholder={t("TransferForm.leaveComment")}
                                            value={formData.note}
                                            onChange={handleChange}
                                            id="narration"
                                        ></textarea>
                                        <label htmlFor="narration">{t("TransferForm.comments")}</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control numberField"
                                            id="recieverCode"
                                            name="optcode"
                                            required="required"
                                            value={formData.optcode}
                                            onChange={handleChange}
                                            placeholder="&nbsp;"
                                        />
                                        <label htmlFor="recieverCode">{t("TransferForm.optCode")}</label>
                                    </div>
                                    <button className="btn btn-primary mt-3 w-100" disabled={isLoading}>
                                        {t("TransferForm.submit")}{" "}
                                        {isLoading ? (
                                            <i className="fas fa-circle-notch fa-spin"></i>
                                        ) : (
                                            <i className="las la-paper-plane"></i>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TransferFormContent;
