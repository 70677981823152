import React from 'react';
import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import DataTable from "react-data-table-component";
import PaymentTable from "../../components/Fee&Transaction/Fees/PaymentTable";
import {useParams} from 'react-router-dom';
import {Context} from "../../context/Contexts";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";
import { useTranslation } from 'react-i18next'; // Translation hook

export const FeeDetail = () => {
    const { t } = useTranslation(); // Translation hook
    const personinfo = useSelector(state => state.user.userInfo);
    setPageTitle(t('FeesDetail.pageTitle')); // Page title translation
    const apptheme = useSelector(state => state.user.userTheme);
    const [payments, setPayments] = React.useState([]);
    const [feeDetail, setFeeDetail] = React.useState([]);
    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const {id} = useParams();

    React.useEffect(() => {
        const req = async () => {
            try {
                const res = await API.post('fees_details', {id}, {headers});
                setPayments(res.data.payments);
                setFeeDetail(res.data.fee);
            } catch (e) {
                errorAlert('Oop', t('FeesDetail.errorMessage'));
            }
        }
        req();
    }, []);

    const formattedDate = (dateString) => {
        return new Date(dateString).toLocaleDateString(t("FeesDetail.locale"), {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const totalPaid = payments && payments.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.amount;
    }, 0);
    const balancetoPay = feeDetail.amount - totalPaid;

    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo} />
            <div className='content'>
                <TopMeunSection personinfo={personinfo} />
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h3>
                                        <a className="dynmatxt" href="/fees">
                                            <i className="fas fa-arrow-left"></i>
                                        </a> {t('FeesDetail.title')}
                                        <b className="text-decoration-underline"> {feeDetail && (`# ${feeDetail.invoiceNo}`)}</b>
                                    </h3>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-7">
                                            <ul className="list-group">
                                                <li className="list-group-item">
                                                    <small>{t('FeesDetail.feeType')}</small>
                                                    <br />
                                                    <b>{feeDetail.type}</b>
                                                </li>
                                                <li className="list-group-item">
                                                    <small>{t('FeesDetail.paymentStatus')}</small>
                                                    <br />
                                                    <b>
                                                        {feeDetail.status ? (
                                                            <span className="badge bg-success">{t('FeesDetail.cleared')}</span>
                                                        ) : (
                                                            <span className="badge bg-warning">{t('FeesDetail.notCleared')}</span>
                                                        )}
                                                    </b>
                                                </li>
                                                <li className="list-group-item">
                                                    <small>{t('FeesDetail.feeAmount')}</small>
                                                    <br />
                                                    <b>{parseInt(feeDetail.amount).toLocaleString()}</b>
                                                </li>
                                                <li className="list-group-item">
                                                    <small>{t('FeesDetail.balanceToPay')}</small>
                                                    <br />
                                                    <b>{balancetoPay.toLocaleString()}</b>
                                                </li>
                                                <li className="list-group-item">
                                                    <small>{t('FeesDetail.academicSection')}</small>
                                                    <br />
                                                    <b>{feeDetail.year}</b>
                                                </li>
                                                <li className="list-group-item">
                                                    <small>{t('FeesDetail.createdOn')}</small>
                                                    <br />
                                                    <b>{formattedDate(feeDetail.createdon)}</b>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-5">
                                            <DataTable
                                                responsive
                                                striped
                                                theme={apptheme ? "dark" : undefined}
                                                direction="auto"
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                                columns={PaymentTable(t)}
                                                data={payments}
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeeDetail;
