import API from "../../api";
import Swal from "sweetalert2";
import React from "react";

const Assessments = (headers, t) => {
    const handleTakeAssessment = async (id) => {
        const confirmResult = await Swal.fire({
            title: t('dashboard.assessments.ready_title'),
            text: t('dashboard.assessments.ready_text'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('dashboard.assessments.confirm_button'),
            cancelButtonText: t('dashboard.assessments.cancel_button'),
        });

        if (confirmResult.isConfirmed) {
            try {
                await API.post('take_assessment', { id }, { headers });
                window.location.replace('/take-assessment/' + id);
            } catch (e) {
                // Handle error
            }
        }
    };

    const buttons = [
        {
            label: t('dashboard.assessments.take_button'),
            className: 'btn btn-sm btn-primary',
            onClick: (id) => () => handleTakeAssessment(id),
        },
    ];

    const formattedDate = (dateString) => {
        const locale = t('dashboard.assessments.language') === 'fr' ? 'fr-FR' : 'en-US'; // Adjust date format based on language
        return new Date(dateString).toLocaleDateString(locale, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    return [
        {
            cell: (row) => (
                <div>
                    {buttons.map((button, index) => (
                        !row.isTaken ? (
                            <button
                                className={button.className}
                                onClick={button.onClick(row.uuid)}
                                key={index}
                            >
                                {button.label} <i className="fas fa-arrow-right"></i>
                            </button>
                        ) : (
                            <a className="btn btn-sm btn-primary" href={`/view-my-score/${row.uuid}`}>
                                {t('dashboard.assessments.result_button')} <i className="fas fa-file-code"></i>
                            </a>
                        )
                    ))}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: t('dashboard.assessments.title'),
            selector: row => row.title,
            sortable: true,
        },
        {
            name: t('dashboard.assessments.assessment_type'),
            selector: row => <span className="text-uppercase">{row.assessmentType}</span>,
            sortable: true,
        },
        {
            name: t('dashboard.assessments.attempted_date'),
            selector: row => row.attemptDate && formattedDate(row.attemptDate),
            sortable: true,
        },
        {
            name: t('dashboard.assessments.duration'),
            selector: row => `${formattedDate(row.startDate)} - ${formattedDate(row.endDate)}`,
            sortable: true,
        },
        {
            name: t('dashboard.assessments.taken'),
            selector: row => (
                <span className={`badge ${row.isTaken ? 'bg-success' : 'bg-primary'}`}>
                    {row.isTaken ? t('dashboard.assessments.attempted') : t('dashboard.assessments.not_attempted')}
                </span>
            ),
            sortable: true,
        },
    ];
}

export default Assessments;
