import { useParams } from "react-router-dom";
import React from "react";
import { Context } from "../../context/Contexts";
import API from "../../api";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import AnsweredScriptLecturer from "./AnsweredScriptLecturer";
import Swal from "sweetalert2";
import successAlert from "../../services/AlertS/sucess";
import { useTranslation } from 'react-i18next';

export const MarkingScriptContent = ({ personinfo }) => {
    const { id } = useParams();
    const { t } = useTranslation(); // Translation hook
    const [examScripts, setExamScripts] = React.useState([]);
    const [reloadExamScripts, setReloadExamScripts] = React.useState(false);
    const { user } = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };

    React.useEffect(() => {
        const req = async () => {
            try {
                const res = await API.post("get_exam_scripts", { id }, { headers });
                setExamScripts(res.data);
            } catch (e) {
                // Handle error
            }
        };
        req();
    }, [reloadExamScripts]);

    const updateStudentPoint = (referenceCode, qUuid, newStudentPoint) => {
        setExamScripts((prevScripts) => {
            const updatedScripts = { ...prevScripts };
            if (updatedScripts[referenceCode]) {
                const updatedScript = updatedScripts[referenceCode].find(
                    (script) => script.qUuid === qUuid
                );
                if (updatedScript) {
                    updatedScript.studentPoint = newStudentPoint;
                    updatedScript.qMarked = true;
                }
            }
            return updatedScripts;
        });
    };

    const studentResponses = Object.entries(examScripts).map(
        ([matricnoIndex, scripts], index) => (
            <div className="p-4" key={matricnoIndex}>
                <span className="badge bg-info mb-2">{matricnoIndex}</span>
                {scripts.map((item, subIndex) => (
                    <AnsweredScriptLecturer
                        key={subIndex}
                        info={item}
                        updateStudentPoint={updateStudentPoint}
                    />
                ))}
            </div>
        )
    );

    const handleSaveProgress = async () => {
        Swal.fire({
            title: t('dashboard.marking_script.saving_title'),
            text: t('dashboard.marking_script.saving_text'),
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading();
            }
        });
        try {
            setTimeout(async () => {
                const res = await API.post("save_marked_scripts", { examScripts, id }, { headers });
                if (res.data === 'saved') {
                    setReloadExamScripts(prevState => !prevState);

                    Swal.close();
                    successAlert(t('dashboard.marking_script.saved_title'), t('dashboard.marking_script.saved_text'));
                }
            }, 1500);
        } catch (e) {
            // Handle error
        }
    };

    return (
        <div className="content">
            <TopMeunSection personinfo={personinfo} />
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h3>
                                        <a href="/list-scripts" className="dynmatxt">
                                            <i className="fas fa-arrow-left"></i>
                                        </a>
                                        {t('dashboard.marking_script.scripts')}
                                    </h3>
                                    <button onClick={handleSaveProgress} className="btn-success btn btn-sm">
                                        {t('dashboard.marking_script.save_progress')}
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <div className="col-sm-12 px-4">
                                        <div className="mx-3 scriptViewZoneLect">
                                            {studentResponses.length > 0 ? studentResponses : (
                                                <h1 className="text-center fw-bold mt-5">
                                                    {t('dashboard.marking_script.no_more_scripts')}
                                                </h1>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkingScriptContent;
