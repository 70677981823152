import React from "react";
import { useTranslation } from 'react-i18next';
import API from "../../api";
import warningAlert from "../../services/AlertS/warning";

export const FIleUpload = ({ formStep, personfileTypes, setUploadFiles, setValue,currentLanguage }) => {
    const { t } = useTranslation();

    const handleFileUpload = (filetype, event) => {
        const file = event.target.files[0];
        let maxSizeFormatted = (String(filetype.maxSize).length > 6) ? String(filetype.maxSize).slice(0, 1) + " MB" :
            String(filetype.maxSize).slice(0, 3) + " KB";
        if (file.size > Number(filetype.maxSize)) {
            warningAlert(t('registration_form.file_upload_error_title'), t('registration_form.file_upload_error_desc', { maxSizeFormatted }));
            event.target.value = ""
            return false;
        }

        const fileData = new FormData();
        fileData.append("file", file);

        setUploadFiles((prevState) => ({
            ...prevState,
            [filetype.uuid]: fileData.get("file"),
            codereference: filetype.codereference
        }));
    };

    const filetypeOutPut = personfileTypes.map((filetype, index) => {
        let fileformat;
        if (filetype.fileFormat === 'images_only') fileformat = '.png,.jpeg,.jpg,.webp';
        if (filetype.fileFormat === 'docs_only') fileformat = '.pdf,.pptx,.doc,.docx';
        if (filetype.fileFormat === 'docs_images_only') fileformat = '.pdf,.pptx,.doc,.docx,.png,.jpeg,.jpg,.webp';

        let maxSizeFormatted = (String(filetype.maxSize).length > 6) ? String(filetype.maxSize).slice(0, 1) + " MB" :
            String(filetype.maxSize).slice(0, 3) + " KB";
        return (
            <div className="col-sm-12 col-md-6" key={filetype.uuid}>
                <div className="mb-3">
                    <label htmlFor={filetype.uuid} className="form-label">
                        {currentLanguage === "en" ? filetype.libelle : currentLanguage === "fr" ? filetype.libelleFr : ""}
                        {filetype.isRequired && (<span className="text-danger small-text">{t('registration_form.required')}</span>)} <small className="text-primary">{t('registration_form.max_size', { maxSizeFormatted })}</small>
                    </label>
                    <input
                        onChange={(event) => handleFileUpload(filetype, event)}
                        className="form-control"
                        type="file"
                        required={filetype.isRequired && "required"}
                        id={filetype.uuid}
                        accept={fileformat}
                    />
                </div>
            </div>
        )
    });

    return (
        <div className={formStep !== 3 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">{t('registration_form.upload_files')}</h3>
            <p className="small text-secondary text-center">{t('registration_form.upload_files_desc')}</p>
            <p className="text-end dynmatxt">{t('registration_form.step')} <small className="fw-bold">{formStep}/4</small></p>
            <div className="row">
                {filetypeOutPut}
            </div>
        </div>
    );
}
export default FIleUpload;
