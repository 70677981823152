export const FeeTable = (hideBtn = null, t) => {
    const buttons = [
        {
            label: '',
            className: 'btn btn-sm btn-primary',
            onClick: () => {
                // Handle button 1 click event
            },
        },
    ];
    return [
        {
            cell: (row) => (
                hideBtn === null &&
                <div>
                    {buttons.map((button, index) => (
                        <a href={`fees-detail/${row.uuid}`} key={index} className={button.className}
                           onClick={button.onClick}>
                            {button.label} <i className="fas fa-arrow-right"></i>
                        </a>
                    ))}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: t('FeesTable.invoice_no'),
            selector: row => row.invoiceNo,
            sortable: true,
        },
        {
            name: t('FeesTable.fee_type'),
            selector: row => <span className="text-uppercase">{row.type}</span>,
            sortable: true,
        },
        {
            name: t('FeesTable.amount'),
            selector: row => row.amount.toLocaleString(),
            sortable: true,
        },
        {
            name: t('FeesTable.status'),
            selector: row => (
                row.status ? <span className="badge bg-primary">{t('FeesTable.cleared')}</span> : <span className="badge bg-danger">{t('FeesTable.not_cleared')}</span>
            ),
            sortable: true,
        },
        {
            name: t('FeesTable.academic_year'),
            selector: row => `${row.semester} - ${row.year}`,
            sortable: true,
        },
    ];
}

export default FeeTable;
