import React from "react";
import API from "../../api";
import { Context } from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";
import { useParams } from "react-router-dom";
import ScoringFields from "./ScoringFields";
import errorAlert from "../../services/AlertS/error";
import successAlert from "../../services/AlertS/sucess";
import { useTranslation } from 'react-i18next'; // Translation hook

export const Content = ({ personinfo }) => {
    const { t } = useTranslation(); // Translation hook
    const { user } = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const { id } = useParams();
    const [studentTakingCourse, setStudentTakingCourse] = React.useState([]);
    const [couserinfo, setCouserinfo] = React.useState([]);
    const [academicYears, setAcademicYears] = React.useState([]);

    React.useEffect(() => {
        const req = async () => {
            try {
                const res = await API.post("get_course_scoresheet", { id }, { headers });
                setStudentTakingCourse(res.data.list);
                setCouserinfo(res.data.course);
                setAcademicYears(res.data.academicYears);
            } catch (e) {
                // Handle error
            }
        };
        req();
    }, []);

    const chanegAcademicYear = (acacdemicid) => {
        const req = async () => {
            try {
                const res = await API.post("get_course_scoresheet", { id, acacdemicid }, { headers });
                setStudentTakingCourse(res.data.list);
                setCouserinfo(res.data.course);
                setAcademicYears(res.data.academicYears);
            } catch (e) {
                // Handle error
            }
        };
        req();
    };

    const handleSave = () => {
        const req = async () => {
            try {
                const res = await API.post("save_scoresheet", studentTakingCourse, { headers });
                successAlert(t('scoresheet.success_title'), t('scoresheet.success_message'));
            } catch (e) {
                errorAlert(t('scoresheet.error_title'), e.response?.data);
            }
        };
        req();
    };

    const scorlide = studentTakingCourse.map((item, index) => {
        index += 1;
        return (
            <ScoringFields
                data={item}
                index={index}
                studentTakingCourse={studentTakingCourse}
                setStudentTakingCourse={setStudentTakingCourse}
            />
        );
    });

    const academicYearsSelect = academicYears.map((option) => {
        return <option value={option.uuid}>{option.year} {option.semester}</option>;
    });

    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo} />
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="mb-4">
                                    <a className="dynmatxt" href="/courses">
                                        <i className="fas fa-arrow-left"></i>
                                    </a>
                                    {couserinfo.title} [{couserinfo.code}] {t('scoresheet.scoresheet_title')}
                                </h3>

                                <div className="d-flex justify-content-between">
                                    <div className="form-floating w-75">
                                        <select
                                            onChange={(e) => chanegAcademicYear(e.target.value)}
                                            className="form-select"
                                            id="floatingSelect"
                                            aria-label={t('scoresheet.select_academic_year')}
                                        >
                                            {academicYearsSelect}
                                        </select>
                                    </div>
                                    <button onClick={handleSave} className="btn btn-primary">
                                        {t('scoresheet.save')} <i className="fas fa-save"></i>
                                    </button>
                                </div>
                                <div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th className="fw-bold dynmatxt">#</th>
                                                <th className="fw-bold dynmatxt">{t('scoresheet.student')}</th>
                                                <th className="fw-bold dynmatxt">{t('scoresheet.test_score')}</th>
                                                <th className="fw-bold dynmatxt">{t('scoresheet.exam_score')}</th>
                                                <th className="fw-bold dynmatxt">{t('scoresheet.attendance_score')}</th>
                                                <th className="fw-bold dynmatxt">{t('scoresheet.assignment_score')}</th>
                                                <th className="fw-bold dynmatxt">{t('scoresheet.total')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {scorlide.length > 0 ? scorlide : (
                                                <h3 className="text-center">
                                                    {t('scoresheet.no_scoresheet')}
                                                </h3>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
