import React from "react";

export const Table = (personinfo, t) => {
	const buttons = [
		{
			label: '',
			className: 'btn btn-sm btn-primary',
			onClick: (id) => {
				// Handle button click event
			},
		},
	];

	const formattedDate = (dateString) => {
		return new Date(dateString).toLocaleDateString(t("TransactionTable.locale"), {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		});
	};

	return [
		{
			cell: (row) => (
				// Render buttons within a cell
				<div>
					{/* Buttons can be rendered here */}
				</div>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
		{
			name: t("TransactionTable.referenceNo"),
			selector: row => row.reference,
			sortable: true,
		},
		{
			name: t("TransactionTable.type"),
			selector: row => <span className="text-uppercase">{row.transtype}</span>,
			sortable: true,
		},
		{
			name: t("TransactionTable.amount"),
			selector: row => (row.amount && parseInt(row.amount).toLocaleString()),
			sortable: true,
		},
		{
			name: t("TransactionTable.sender"),
			selector: row => `${row.senderFirstname}  ${row.senderLastname}`,
			sortable: true,
		},
		{
			name: t("TransactionTable.receiver"),
			selector: row => `${row.recieverFirstname} ${row.recieverLastname}`,
			sortable: true,
		},
		{
			name: t("TransactionTable.bbt"),
			selector: row => row.senderId === personinfo.id
				? (row.balanceBefore && row.balanceBefore.toLocaleString())
				: (row.balanceBeforeReciever && row.balanceBeforeReciever.toLocaleString()),
			sortable: true,
		},
		{
			name: t("TransactionTable.issuedOn"),
			selector: row => formattedDate(row.created),
			sortable: true,
		},
	];
}

export default Table;
