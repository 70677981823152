import React from "react";
import API from "../../api";

export const StudentInfo = ({personinfo, headers, t}) => {
    const [personDetail, setPersonDetail] = React.useState([]);

    React.useEffect(() => {
        API.post('student-info', {}, {headers})
            .then(res => setPersonDetail(res.data));
    }, []);

    return (
        <ul className="list-group mainnetinfo">
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.fullname')}</span>
                <b>{personinfo.firstname} {personinfo.lastname}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.matric_no')}</span>
                <b>{personDetail.matricNo}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.gender')}</span>
                <b>{personDetail.sex ? t('dashboard.student.male') : t('dashboard.student.female')}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.email')}</span>
                <b>{personDetail.email}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.telephone')}</span>
                <b>{personDetail.telephone}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.whatsapp')}</span>
                <b>{personDetail.whatsapptelephone}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.level')}</span>
                <b>{personDetail.currentLevel}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.department')}</span>
                <b>{personDetail.departmentTitle} | {personDetail.departmentCode}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.student.program')}</span>
                <b>{personDetail.program}</b>
            </li>
        </ul>
    );
};

export default StudentInfo;
