import React from "react";
import { useTranslation } from 'react-i18next';

export const SummaryInfo = ({ formStep, studentData, programs, level, admissionType, errors, errorMessageRender }) => {
    const { t } = useTranslation();

    const programsArry = Object.values(programs);
    const programsObj = programsArry.find((obj) => obj.id === studentData.program);
    const admissionTypeArray = Object.values(admissionType);
    const admissionTypeObj = admissionTypeArray.find((obj) => obj.id === studentData.admissionType);
    const levelArray = Object.values(level);
    const levelArrayObj = levelArray.find(item => item.id === parseInt(studentData.level));

    const downloadFile = (fileDetails) => {
        if (!fileDetails) {
            return;
        }

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(fileDetails);
        downloadLink.download = fileDetails.name;
        downloadLink.textContent = `Download ${fileDetails.name}`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className={formStep !== 5 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">{t('registration_form.registration_summary')}</h3>
            <p className="small text-secondary text-center">{t('registration_form.registration_summary_desc')}</p>
            <div className="px-2">
                <h5 className="my-3 dynmatxt text-uppercase">{t('registration_form.basic_info')}</h5>
                <ul className="list-group">
                    <li className="list-group-item"><b>{t('registration_form.surname')}:</b> {studentData?.lastname}</li>
                    <li className="list-group-item"><b>{t('registration_form.other_names')}:</b> {studentData?.firstname}</li>
                    <li className="list-group-item"><b>{t('registration_form.date_of_birth')}:</b> {studentData?.dateofbirth}</li>
                    <li className="list-group-item"><b>{t('registration_form.email')}:</b> {studentData?.emailadress}</li>
                    <li className="list-group-item"><b>{t('registration_form.telephone_number')}:</b> {studentData?.telephonecallcode?.label}{studentData?.telephonenumber}</li>
                    <li className="list-group-item"><b>{t('registration_form.whatsapp_number')}:</b> {studentData?.whatsappcallcode?.label}{studentData?.whatsappnumber}</li>
                    <li className="list-group-item"><b>{t('registration_form.nationality')}:</b> {studentData?.country?.label}</li>
                    <li className="list-group-item"><b>{t('registration_form.gender')}:</b> {studentData?.gender === '1' ? t('registration_form.male') : t('registration_form.female')}</li>
                    <li className="list-group-item"><b>{t('registration_form.address')}:</b> {studentData?.address}</li>
                </ul>

                <h5 className="my-3 dynmatxt text-uppercase">{t('registration_form.academic_info')}</h5>
                <ul className="list-group">
                    <li className="list-group-item"><b>{t('registration_form.admission_type')}:</b> {admissionTypeObj?.libelle}</li>
                    <li className="list-group-item"><b>{t('registration_form.level')}:</b> {levelArrayObj?.title}</li>
                    <li className="list-group-item"><b>{t('registration_form.program')}:</b> {programsObj?.libelle}</li>
                    <li className="list-group-item"><b>{t('registration_form.recent_certificate')}:</b> {studentData?.certificate?.label}</li>
                    <li className="list-group-item"><b>{t('registration_form.no_of_sittings')}:</b> {studentData?.noofsitting}</li>
                    <li className="list-group-item"><b>{t('registration_form.department')}:</b> {studentData?.department?.label}</li>
                    <li className="list-group-item"><b>{t('registration_form.school_discovery')}:</b> {studentData?.schoolDiscovery?.label}</li>
                </ul>

                <h5 className="my-3 dynmatxt text-uppercase">{t('registration_form.guardian_info')}</h5>
                <ul className="list-group">
                    <li className="list-group-item"><b>{t('registration_form.guardian_fullname')}:</b> {studentData?.gfullname}</li>
                    <li className="list-group-item"><b>{t('registration_form.guardian_telephone')}:</b> {studentData?.gtelephonecallcode?.label}{studentData?.gnumber}</li>
                    <li className="list-group-item"><b>{t('registration_form.guardian_email')}:</b> {studentData?.gemail}</li>
                    <li className="list-group-item"><b>{t('registration_form.guardian_address')}:</b> {studentData?.gaddress}</li>
                    <li className="list-group-item"><b>{t('registration_form.relation_to_prospect')}:</b> {studentData?.grelation}</li>
                </ul>

                <h5 className="my-3 dynmatxt">{t('registration_form.files')}</h5>
                {studentData?.fileUploads && Object.keys(studentData?.fileUploads).length > 0 ? (
                    Object.keys(studentData?.fileUploads).map((fileKey) => (
                        studentData?.fileUploads[fileKey]?.name && (
                            <div className="d-flex" key={fileKey}>
                                <p className="dynmatxt">{studentData?.fileUploads[fileKey]?.name}</p>
                                &nbsp;
                                <a className="btn-sm dynmatxt text-uppercase" target="_blank" title={t('registration_form.download')} onClick={() => downloadFile(studentData?.fileUploads[fileKey])}>
                                    <i className="fas fa-download"></i>
                                </a>
                            </div>
                        )
                    ))
                ) : (
                    <h6 className="fw-bold text-danger">{t('registration_form.no_files_uploaded')}</h6>
                )}
            </div>
        </div>
    );
};

export default SummaryInfo;
