import React from "react";
import { Context } from "../../context/Contexts";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import API from "../../api";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import AssessmentTable from "./AssessmentTable";
import { useTranslation } from 'react-i18next';

export const Content = ({ personinfo }) => {
    const { user } = React.useContext(Context);
    const { t, i18n } = useTranslation(); // Translation hook
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [studentAssessement, setStudentAssessement] = React.useState([]);
    const [pending, setPending] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const req = async () => {
                try {
                    const res = await API.post('student_scheduled_assessment', {}, { headers });
                    setStudentAssessement(res.data);
                    setPending(false);
                } catch (e) {
                    // Handle error
                }
            };
            req();
        }, 1500);

        return () => clearTimeout(timeout);
    }, []);

    const apptheme = useSelector(state => state.user.userTheme);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredData = studentAssessement.filter((row) =>
        row.title.toLowerCase().includes(searchText.toLowerCase()) ||
        row.assessmentType.toLowerCase().includes(searchText.toLowerCase())
    );

    const formattedDate = (dateString) => {
        const locale = i18n.language === 'fr' ? 'fr-FR' : 'en-US'; // Adjust date format based on language
        return new Date(dateString).toLocaleDateString(locale, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const ExpandedComponent = ({ data }) => (
        <div>
            <p>
                {t('dashboard.assessments.title')}: <b>{data.title}</b>
            </p>
            <p>
                {t('dashboard.assessments.attempted_date')}: <b>{data.attemptDate && formattedDate(data.attemptDate)}</b>
            </p>
            <p>
                {t('dashboard.assessments.duration')}: <b>{`${formattedDate(data.startDate)} - ${formattedDate(data.endDate)}`}</b>
            </p>
            <p>
                {t('dashboard.assessments.taken')}: <span className={`badge ${data.isTaken ? 'bg-success' : 'bg-primary'}`}>
                    {data.isTaken ? t('dashboard.assessments.attempted') : t('dashboard.assessments.not_attempted')}
                </span>
            </p>
        </div>
    );

    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo} />
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h3>{t('dashboard.assessments.my_exams_tests')}</h3>
                                <div className="mt-4">
                                    <input
                                        type="search"
                                        className="form-control mb-3"
                                        value={searchText}
                                        onChange={handleSearch}
                                        placeholder={t('dashboard.assessments.search_placeholder')}
                                    />
                                    <DataTable
                                        responsive
                                        striped
                                        theme={apptheme ? "dark" : undefined} // Fixed theme assignment
                                        direction="auto"
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                        columns={AssessmentTable(headers,t)}
                                        progressPending={pending}
                                        data={filteredData}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
