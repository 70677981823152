import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enLang from './i18n/locales/en/translation.json'
import frLang from './i18n/locales/fr/translation.json'
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: enLang,
    },
    fr:{
        translation: frLang,
    }
}

const savedLanguage = localStorage.getItem('i18nextLng') || 'fr';
i18n
    // .use(Backend) // Load translations using HTTP (or file-based)
    .use(LanguageDetector) // Detect user language
    .use(initReactI18next) // Pass the i18n instance to react-i18next
    .init({
        resources,
        lng: savedLanguage,
        fallbackLng: 'en-US', // Fallback language when translation is missing
        debug: true, // Enable debug logs in console
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false, // React already escapes content
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to translation files
        },
    });

export default i18n;
