import React from "react";
import API from "../../api";

export const AgentInfo = ({personinfo, headers, t}) => {
    const [personDetail, setPersonDetail] = React.useState([]);

    React.useEffect(() => {
        API.post('agent-info', {}, {headers})
            .then(res => setPersonDetail(res.data))
    }, []);

    return (
        <ul className="list-group mainnetinfo">
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.agent.fullname')}</span>
                <b>{personinfo.firstname} {personinfo.lastname}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.agent.agent_code')}</span>
                <b>{personDetail.code}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.agent.gender')}</span>
                <b>{personDetail.sex ? t('dashboard.agent.male') : t('dashboard.agent.female')}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.agent.email')}</span>
                <b>{personDetail.email}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.agent.telephone')}</span>
                <b>{personDetail.telephone}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>{t('dashboard.agent.whatsapp')}</span>
                <b>{personDetail.whatsapptelephone}</b>
            </li>
        </ul>
    );
};

export default AgentInfo;
